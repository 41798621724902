import { defineComponent } from 'vue';
import moment from 'moment-timezone';
export default defineComponent({
    name: 'WatFocusTesterCalendar',
    props: {
        ranges: {
            type: Array,
            default: function () { return []; }
        },
        slots: {
            type: Array,
            default: function () { return []; }
        },
        sessionMinutes: {
            type: Number,
            default: 30
        }
    },
    computed: {
        disabledButton: function () {
            return this.slotIds.length == 0;
        }
    },
    data: function () {
        return {
            calendarDates: [{
                    date: new Date(),
                    ranges: [{ from: "00:00", to: "00:00", id: 0 }],
                    disabled: false
                }],
            availableHours: new Array(),
            slotIds: new Array()
        };
    },
    methods: {
        goToIndex: function () {
            this.$router.push({ name: 'tests.index' });
        },
        calendarBack: function () {
            var element = document.getElementById("WatFocusTesterCalendarDates");
            element.scrollLeft -= 100;
        },
        calendarNext: function () {
            var element = document.getElementById("WatFocusTesterCalendarDates");
            element.scrollLeft += 100;
        },
        generateCalendar: function () {
            this.calendarDates = [];
            var firstRange = this.ranges[0];
            var startDate = new Date();
            if (firstRange) {
                var daysBetween = this.getDaysBetweenDates(startDate, new Date(firstRange.dateFrom));
                startDate.setDate(startDate.getDate() + daysBetween);
            }
            else {
                startDate.setDate(startDate.getDate() + 3);
            }
            for (var i = 0; i <= 30; i++) {
                var date = this.getDate(startDate, i);
                var calendarDay = {
                    date: date,
                    ranges: [],
                    disabled: this.isSunday(date)
                };
                this.calendarDates.push(calendarDay);
            }
        },
        isSunday: function (date) {
            return date.getDay() === 0;
        },
        sameDayDate: function (date1, date2) {
            return date1.getFullYear() === date2.getFullYear() &&
                date1.getMonth() === date2.getMonth() &&
                date1.getDate() === date2.getDate();
        },
        getDate: function (date, days) {
            var newDate = new Date(date);
            newDate.setDate(newDate.getDate() + days);
            return newDate;
        },
        getDaysBetweenDates: function (date1, date2) {
            var timeDifference = date2.getTime() - date1.getTime();
            var dayDifference = timeDifference / (1000 * 60 * 60 * 24);
            return Math.floor(dayDifference);
        },
        generateAvailableSessions: function () {
            this.availableHours = [
                "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00"
            ];
            var sessions = [];
            var sessionTimeInMs = this.sessionMinutes * 60 * 1000;
            var _a = this.availableHours[0].split(":").map(Number), hours = _a[0], minutes = _a[1];
            var startTime = new Date(0, 0, 0, hours, minutes);
            while (startTime.getHours() < 20 || (startTime.getHours() === 20 && startTime.getMinutes() === 0)) {
                var formattedTime = startTime.toTimeString().slice(0, 5);
                sessions.push(formattedTime);
                startTime = new Date(startTime.getTime() + sessionTimeInMs);
                if (startTime.getHours() >= 20 && startTime.getMinutes() > 0)
                    break;
            }
            this.availableHours = sessions;
        },
        isAvailableSlot: function (hour, calendarDay) {
            return this.getSlot(hour, calendarDay) != null ? true : false;
        },
        isSelectedSlot: function (hour, calendarDay) {
            var slot = this.getSlot(hour, calendarDay);
            if (slot == null)
                return false;
            return this.slotIds.includes(slot.id);
        },
        getSlot: function (hour, calendarDay) {
            for (var _i = 0, _a = this.slots; _i < _a.length; _i++) {
                var slot = _a[_i];
                var s = slot;
                if (this.sameDayDate(calendarDay.date, new Date(s.dateFrom)) && moment(s.dateFrom).format("HH:mm") == hour) {
                    return slot;
                }
            }
            return null;
        },
        selectSlot: function (hour, calendarDay) {
            var s = this.getSlot(hour, calendarDay);
            if (this.slotIds.includes(s.id))
                this.slotIds.splice(this.slotIds.indexOf(s.id), 1);
            else if (s != null)
                this.slotIds.push(s.id);
        }
    },
    mounted: function () {
        this.generateCalendar();
        this.generateAvailableSessions();
    }
});
